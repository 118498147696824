import React from 'react';
import { Navigate, useRoutes } from "react-router-dom";
const ProvidedLimits = React.lazy(() => import("./features/master-data/provided-limits/ProvidedLimits"));
const TestSets = React.lazy(() => import("./features/master-data/test-sets/TestSets"));
const TestItems = React.lazy(() => import("./features/master-data/test-items/TestItems"));
const SearchRegistration = React.lazy(() => import("./features/quick-details/registrations/SearchRegistration"));
const AutoEvaluation = React.lazy(() => import("./features/trays/04-auto-evaluation/AutoEvaluation"));
const Evaluation = React.lazy(() => import("./features/trays/05-evaluation/Evaluation"));
const CreateTray = React.lazy(() => import("./features/trays/create-tray/CreateTray"));
const MissedSamples = React.lazy(() => import("./features/reports/missed-samples/MissedSamples"));
const Registration = React.lazy(() => import("./features/trays/01-registration/Registration"));
const Registration2 = React.lazy(() => import("./features/trays/01-registration2/Registration"));

const QualityCheck = React.lazy(() => import("./features/trays/quality-check/QualityCheck"));
const SelectImage = React.lazy(() => import("./components/SelectImage"));
const Chart = React.lazy(() => import("./features/charts/Chart"));
const FluidWatch = React.lazy(() => import("./features/master-data/fluids/FluidWatch"));
const Review = React.lazy(() => import("./features/trays/review/Review"));
const EditContacts = React.lazy(() => import("./forms/EditContacts"));
const AddCriticalLogs = React.lazy(() => import("./features/reports/critical-logs/add-critical-logs/AddCriticalLogs"));
const SearchContact = React.lazy(() => import("./features/quick-details/SearchContact"));
const Fluids = React.lazy(() => import("./features/master-data/fluids/Fluids"));
const CustomerParents = React.lazy(() => import("./features/customer/Parents"));
const UnitModels = React.lazy(() => import("./features/master-data/unit-models/UnitModels"));
const ComponentModels = React.lazy(() => import("./features/master-data/components-model/ComponentModels"));
const SiteSets = React.lazy(() => import("./features/master-data/sites-set/SiteSets"));
const UsersOverview = React.lazy(() => import("./features/management/UsersOverview"));
const AuditLogs = React.lazy(() => import("./features/reports/audit-logs/AuditLogs"));
const Laboratories = React.lazy(() => import("./features/management/Laboratories"));
const Products = React.lazy(() => import("./features/management/Products"));
const AutoEvaluationComments = React.lazy(() => import("./features/management/AutoEvaluationComments"));
const UnitHourHistory = React.lazy(() => import("./features/quick-details/unit-hour-history/UnitHourHistory"));
const RecentUnitResults = React.lazy(() => import("./features/quick-details/recent-unit-result/RecentUnitResults"));
const PresetComments = React.lazy(() => import("./features/management/preset-comments/PresetComments"));
const Messages = React.lazy(() => import("./features/management/Messages"));
const CustomerSites = React.lazy(() => import("./features/management/CustomerSites"));

const QuickMergeFluid = React.lazy(() => import("./features/master-data/quick-merge-fluid/QuickMergeFluid"));
const QuickMergeModel = React.lazy(() => import("./features/master-data/quick-merge-model/QuickMergeModel"));
const QuickMoveSites = React.lazy(() => import("./features/master-data/quick-move-sites/QuickMoveSites"));
const QuickMergeSites = React.lazy(() => import("./features/master-data/quick-merge-sites/QuickMergeSites"));
const Cleanup = React.lazy(() => import("./features/dashboards/cleanup/Cleanup"));
const IkowaFeedback = React.lazy(() => import("./features/dashboards/ikowa-feedback/IkowaFeedback"));
const RerunSamples = React.lazy(() => import("./features/reports/rerun-samples/RerunSamples"));
const HoldManagement = React.lazy(() => import("./features/management/HoldManagement"));
const CriticalLogs = React.lazy(() => import("./features/reports/critical-logs/CriticalLogs"));
const AccessPermissionOverview = React.lazy(() => import("./features/reports/critical-logs/access-permission-overview/AccessPermissionOverview"));
const InsiteMachineSearch = React.lazy(() => import("./features/master-data/unit-models/InsiteMachineSearch"));
const InsiteModelSearch = React.lazy(() => import("./features/master-data/unit-models/InsiteModelSearch"));
const MachineTemplates = React.lazy(() => import("./features/master-data/machine-templates/MachineTemplates"));
const GroupEmailDomains = React.lazy(() => import("./features/management/GroupEmailDomains"));
const MachineNotifications = React.lazy(() => import("./features/management/MachineNotifications"));
const ApprovalToRelease = React.lazy(() => import("./features/trays/approval-to-release/ApprovalToRelease"));

const EmailsAndSamplesReports = React.lazy(() => import("./features/quick-details/manage/email-sample-report/EmailsAndSamplesReports"));
const QuickDetails = React.lazy(() => import("./features/details-old/QuickDetails"));
const EditTray = React.lazy(() => import("./forms/EditTray"));
const SpecialReport = React.lazy(() => import("./forms/SpecialReport"));
const SpecialReportTemplates = React.lazy(() => import("./features/reports/special-reports/SpecialReportTemplates"));
const SpecialReportTemplatesTypes = React.lazy(() => import("./features/reports/special-reports/SpecialReportTemplatesTypes"));
const ReportToMail = React.lazy(() => import("./features/reports/report-to-mail/ReportToMail"));
const FluidNotifications = React.lazy(() => import("./features/management/Notifications/FluidNotifications"));
const ProductNotifications = React.lazy(() => import("./features/management/Notifications/ProductNotifications"));
const Hold = React.lazy(() => import("./features/trays/laboratory/Hold"));
const ManagementReport = React.lazy(() => import("./features/management/ManagementReport"));
const EditBillingRates = React.lazy(() => import("./features/management/EditBillingRates"));
const GlobalSettings = React.lazy(() => import("./features/management/GlobalSettings"));
const WebsiteNews = React.lazy(() => import("./features/management/WebsiteNews/WebsiteNews"));
const PrimaryContactOverview = React.lazy(() => import("./features/management/primary-contact-overview/PrimaryContactOverview"));
const ContactExports = React.lazy(() => import("./features/reports/critical-logs/contact-permission-overview/ContactExports"));
const SampleReportsOverview = React.lazy(() => import("./features/management/sample-report-overview/SampleReportsOverview"));
const SubscriptionOverview = React.lazy(() => import("./features/quick-details/subscription/SubscriptionOverview"));
const EditSample = React.lazy(() => import("./forms/EditSample"));
const EditCustomer = React.lazy(() => import("./forms/EditCustomer"));
const EditSite = React.lazy(() => import("./forms/EditSite"));
const EditUnit = React.lazy(() => import("./forms/EditUnit"));
const EditComponent = React.lazy(() => import("./forms/EditComponent"));
const RecentComponentResults = React.lazy(() => import("./features/quick-details/recent-component-result/RecentComponentResults"));
const ManageSample = React.lazy(() => import("./features/quick-details/manage/manage-sample/ManageSample"));
const ManageComponent = React.lazy(() => import("./features/quick-details/manage/manage-component/ManageComponent"));
const ManageUnit = React.lazy(() => import("./features/quick-details/manage/manage-unit/ManageUnit"));
const ManageSite = React.lazy(() => import("./features/quick-details/manage/manage-site/ManageSite"));
const ManageCustomer = React.lazy(() => import("./features/quick-details/manage/manage-customer/ManageCustomer"));
const Laboratory2 = React.lazy(() => import("./features/trays/laboratory/Laboratory2"));
const RerunToLaboratory = React.lazy(() => import("./features/trays/quality-check/RerunToLaboratory"));
const SearchFluid = React.lazy(() => import("./features/master-data/fluids/SearchFluid"));
const NewContacts = React.lazy(() => import("./forms/NewContacts"));
const EditEvent = React.lazy(() => import("./features/events/EditEvent"));
const ViewEvents = React.lazy(() => import("./features/events/ViewEvents"));
const SubscriptionReports = React.lazy(() => import("./components/SubscriptionReports"));
const UnitsRecentSamples = React.lazy(() => import("./features/quick-details/recent-samples/UnitsRecentSamples"));
const ComponentsRecentSamples = React.lazy(() => import("./features/quick-details/recent-samples/ComponentsRecentSamples"));
const SearchGeneral = React.lazy(() => import("./features/details-old/SearchGeneral"));
const Paperless = React.lazy(() => import("./features/trays/paperless/Paperless"));
const Paperless2 = React.lazy(() => import("./features/trays/paperless2/Paperless2"));
const EditRegistration = React.lazy(() => import("./features/quick-details/registrations/ER/EditRegistration"));
const ContactAccessPermission = React.lazy(() => import("./features/reports/critical-logs/contact-permission-overview/ContactAccessPermission"));
const ContactPrimary = React.lazy(() => import("./features/reports/critical-logs/contact-permission-overview/ContactPrimary"));
const ApplyCMSRole = React.lazy(() => import("./features/management/ApplyCMSRole"));
const MatchMachinesInsite = React.lazy(() => import("./features/management/MatchMachinesInsite"));
const KomatsuContacts = React.lazy(() => import("./features/management/KomatsuContacts"));
const EditUnitModel = React.lazy(() => import("./forms/EditUnitModel"));
const AutoEvaluationDashboard = React.lazy(() => import("./features/dashboards/autoevaluation-dash/AutoEvaluationDashboard"));
const ImportHistory = React.lazy(() => import("./features/master-data/import-history/ImportHistory"));
// import TrayDashboard from "./features/dashboards/tray/TrayDashboard";

const TrayDashboard = React.lazy(() => import('./features/dashboards/tray/TrayDashboard'));

export default function AppRouter({ user }) {

  const adminRoutes = [
    { path: "management/usersOverview", element: <UsersOverview /> },
    { path: "reports/audit-Log", element: <AuditLogs /> },
    { path: "management/group-email-domains", element: <GroupEmailDomains />, },
    { path: "management/auto-evaluation-comments", element: <AutoEvaluationComments />, },
    { path: "management/laboratories", element: <Laboratories /> },
    { path: "management/products", element: <Products /> },
    { path: "management/management-report", element: <ManagementReport /> },
    { path: "management/edit-billing-rates", element: <EditBillingRates /> },
    { path: "management/global-settings", element: <GlobalSettings /> },
    { path: "management/website-news", element: <WebsiteNews /> },

    { path: "admin/komatsu-contacts", element: <KomatsuContacts /> },
    { path: "admin/match-machines-insite", element: <MatchMachinesInsite /> },
    { path: "admin/apply-cms-role", element: <ApplyCMSRole /> },

  ];

  const nonAdminRoutes = [

    { exact: true, path: "/", element: <TrayDashboard /> },
    { path: "trays/registration2", element: <Registration2 /> },

    {
      path: "manage-sample",
      element: <ManageSample />,
    },
    {
      path: "manage-component",
      element: <ManageComponent />,
    },
    {
      path: "manage-unit",
      element: <ManageUnit />,
    },
    {
      path: "manage-site",
      element: <ManageSite />,
    },
    {
      path: "manage-customer",
      element: <ManageCustomer />,
    },
    {
      path: "recent-unit-results",
      element: <RecentUnitResults />,
    },
    {
      path: "recent-component-results",
      element: <RecentComponentResults />,
    },
    {
      path: "add-critical-logs",
      element: <AddCriticalLogs />,
    },
    {
      path: "unit-hour-history",
      element: <UnitHourHistory />,
    },
    {
      path: "contact-primary-mapping",
      element: <ContactPrimary />,
    },
    {
      path: "primary-contacts-overview",
      element: <PrimaryContactOverview />,
    },
    {
      path: "export-contacts-overview",
      element: <ContactExports />,
    },
    {
      path: "report-contacts",
      element: <SampleReportsOverview />,
    },
    {
      path: "subscription-overview",
      element: <SubscriptionOverview />,
    },
    {
      path: "management/preset-comments",
      element: <PresetComments />,
    },
    {
      path: "management/messages",
      element: <Messages />,
    },
    {
      path: "management/machine-notifications",
      element: <MachineNotifications />,
    },
    {
      path: "management/customerSites",
      element: <CustomerSites />,
    },
    {
      path: "settings/fluid-type-notifications",
      element: <FluidNotifications />,
    },
    {
      path: "settings/product-notifications",
      element: <ProductNotifications />,
    },
    {
      path: "management/email-and-sample-reports",
      element: <EmailsAndSamplesReports />,
    },
    { path: "management/add-contact", element: <NewContacts /> },
    { path: "management/edit-contact", element: <EditContacts /> },

    { path: "return-to-lab", element: <RerunToLaboratory /> },
    { path: "search-fluid", element: <SearchFluid /> },
    { path: "search-general", element: <SearchGeneral /> },

    { path: "reports-by-date", element: <SubscriptionReports /> },

    { path: "special-unit-report", element: <SpecialReport /> },
    { path: "special-component-report", element: <SpecialReport /> },
    { path: "view-event", element: <ViewEvents /> },
    { path: "edit-event", element: <EditEvent /> },
    { path: "add-event", element: <EditEvent /> },

    { path: "units-no-recent-samples", element: <UnitsRecentSamples /> },
    { path: "components-no-recent-samples", element: <ComponentsRecentSamples /> },

    { path: "master-data/provided-limits", element: <ProvidedLimits /> },
    { path: "master-data/test-set", element: <TestSets /> },
    { path: "master-data/test-items", element: <TestItems /> },
    { path: "registrations/search", element: <SearchRegistration />, },
    { path: "trays/create", element: <CreateTray /> },
    { path: "trays/registration", element: <Registration /> },
    { path: "trays/laboratory", element: <Laboratory2 /> },
    { path: "trays/quality-check", element: <QualityCheck /> },
    { path: "trays/auto-evaluation", element: <AutoEvaluation /> },
    { path: "trays/evaluation", element: <Evaluation /> },
    { path: "trays/approval", element: <ApprovalToRelease /> },
    { path: "trays/review", element: <Review /> },
    { path: "details/quick", element: <QuickDetails /> },
    { path: "dashboards/tray", element: <TrayDashboard /> },
    { path: "missed-samples", element: <MissedSamples /> },
    { path: "chart", element: <Chart /> },
    { path: "master-data/fluids", element: <Fluids /> },
    { path: "master-data/quick-merge-fluid", element: <QuickMergeFluid /> },
    { path: "master-data/quick-merge-model", element: <QuickMergeModel /> },
    { path: "master-data/quick-move-sites", element: <QuickMoveSites /> },
    { path: "master-data/quick-merge-sites", element: <QuickMergeSites /> },
    { path: "master-data/import-history", element: <ImportHistory /> },

    { path: "reports/critical-logs", element: <CriticalLogs /> },

    // { path: "reports/customer-feedback", element: <CustomerFeedback /> },
    { path: "scan-bottle", element: <Paperless /> },
    { path: "create-trays-qrcode", element: <Paperless2 /> },

    { path: "test/chart", element: <Chart /> },
    { path: "images", element: <SelectImage />, },
    {
      path: "trays/edit-registration",
      element: <EditRegistration />,
    },

    {
      path: "edit-customer",
      element: <EditCustomer />,
    },
    {
      path: "add-new-customer",
      element: <EditCustomer />,
    },
    {
      path: "edit-site",
      element: <EditSite />,
    },
    {
      path: "add-new-site",
      element: <EditSite />,
    },
    {
      path: "edit-unit",
      element: <EditUnit />,
    },
    {
      path: "add-new-unit",
      element: <EditUnit />,
    },
    {
      path: "edit-component",
      element: <EditComponent />,
    },
    {
      path: "add-new-component",
      element: <EditComponent />,
    },

    {
      path: "trays/edit-sample",
      element: <EditSample />,
    },
    {
      path: "trays/edit-tray",
      element: <EditTray />,
    },
    {
      path: "master-data/FluidWatch",
      element: <FluidWatch fluidId={null} isShowTitle={true} />,
    },
    {
      path: "customer/Parents",
      element: <CustomerParents />,
    },
    {
      path: "management/search-contact",
      element: <SearchContact />,
    },
    {
      path: "dashboards/auto-evaluation",
      element: <AutoEvaluationDashboard />,
    },
    {
      path: "dashboards/ikowa-feedback",
      element: <IkowaFeedback />,
    },
    {
      path: "dashboards/Cleanup",
      element: <Cleanup />,
    },
    {
      path: "access-permission-overview",
      element: <AccessPermissionOverview />,
    },
    {
      path: "contact-access-permission",
      element: <ContactAccessPermission />,
    },
    {
      path: "master-data/unit-models",
      element: <UnitModels />,
    },
    {
      path: "master-data/component-models",
      element: <ComponentModels />,
    },
    {
      path: "master-data/machine-templates",
      element: <MachineTemplates />,
    },
    {
      path: "master-data/view-komatsu-machines",
      element: <InsiteMachineSearch />,
    },
    {
      path: "master-data/view-komatsu-models",
      element: <InsiteModelSearch />,
    },
    {
      path: "master-data/SiteSets",
      element: <SiteSets />,
    },
    {
      path: "reports/rerun-samples",
      element: <RerunSamples />,
    },
    {
      path: "reports/missed-and-hold-samples",
      element: <MissedSamples />,
    },
    {
      path: "reports/special-report",
      element: <SpecialReportTemplates />,
    },
    {
      path: "reports/special-report-types",
      element: <SpecialReportTemplatesTypes />,
    },
    {
      path: "reports/reports-email",
      element: <ReportToMail />,
    },
    {
      path: "reports/email-selected-items",
      element: <EmailsAndSamplesReports />,
    },
    {
      path: "management/hold-management",
      element: <HoldManagement />,
    },
    {
      path: "hold",
      element: <Hold />
    },
    {
      path: "new-unit-model",
      element: <EditUnitModel />
    },
    {
      path: "*",
      element: <Navigate to="/" />
    },
  ]
  let routes = ((user?.RoleAdministration || user?.RoleDeveloper) === true) ? [...adminRoutes, ...nonAdminRoutes] : nonAdminRoutes
  let element = useRoutes(routes);

  return element;
}
