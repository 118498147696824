export function useWindowPopups(windowType, data, tabCheck) {

    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const windowDimesions = (width, height) => {
        return "width=" + (width || windowWidth) + ",height=" + (height || windowHeight) + ",top=30,left=20,resizable,scrollbars=yes";
    }

    if (windowType === WindowTypes.SEARCH_GENERAL) {
        // localStorage.setItem("search-general-" + data?.ScreenName, JSON.stringify({
        //     ContactId: data?.ContactId,
        //     ProductId: data?.ProductId,
        //     SiteSetId: data?.SiteSetId,
        //     ParentId: data?.ParentId,
        //     CustomerId: data?.CustomerId,
        //     SiteId: data?.SiteId,
        //     UnitId: data?.UnitId,
        //     ComponentId: data?.ComponentId,
        //     SampleNumber: data?.SampleNumber,
        //     ReportNumber: data?.ReportNumber,
        //     ScreenName: "search-general-" + (data?.ScreenName || ""),
        // }))
        return window.open(`/search-general?sn=${data?.ScreenName || ""}`, "_blank", windowDimesions("1875px", "800px"));
    };

    if (
        windowType === WindowTypes.WEBSITE_CONTACTS ||
        windowType === WindowTypes.ACCESS_PERMISSION_OVERVIEW
    ) {
        localStorage.setItem("access-permission-overview", JSON.stringify({
            ContactId: data?.ContactId,
            ProductId: data?.ProductId,
            SiteSetId: data?.SiteSetId,
            ParentId: data?.ParentId,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            SampleNumber: data?.SampleNumber,
            ReportNumber: data?.ReportNumber,
            selectCheck: data?.selectCheck,
        }))
        return window.open(`/access-permission-overview?sn=${data?.SampleNumber || ""}`, "_blank", windowDimesions("1875px", "850px"));
    };
    if (windowType === WindowTypes.CONTACT_ACCESS_PERMISSION) {
        localStorage.setItem("contact-access-permission", JSON.stringify({
            ContactId: data?.ContactId,
            PropId: data?.PropId,
            UserName: data?.UserName,
            DisplayName: data?.DisplayName,
            IsKomatsu: data?.IsKomatsu,

        }))
        return window.open(`/contact-access-permission`, "_blank", windowDimesions("1875px", "700px"));
    };
    if (windowType === WindowTypes.CONTACT_PRIMARY_MAPPING) {
        localStorage.setItem("contact-primary-mapping", JSON.stringify({
            ContactId: data?.ContactId,
            PropId: data?.PropId,
            UserName: data?.UserName,
            DisplayName: data?.DisplayName,
            IsKomatsu: data?.IsKomatsu,

        }))
        return window.open(`/contact-primary-mapping`, "_blank", windowDimesions("1875px", "700px"));
    };
    if (windowType === WindowTypes.PRIMARY_CONTACTS_OVERVIEW) {
        localStorage.setItem("primary-contacts-overview", JSON.stringify({
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            SampleNumber: data?.SampleNumber,
            ReportNumber: data?.ReportNumber,
        }))
        return window.open(`/primary-contacts-overview`, "_blank", windowDimesions("1875px", "700px"));
    };

    if (windowType === WindowTypes.EXPORT_CONTACTS_OVERVIEW) {
        localStorage.setItem("export-contacts-overview", JSON.stringify({
            ContactId: data?.ContactId,
            ProductId: data?.ProductId,
            SiteSetId: data?.SiteSetId,
            ParentId: data?.ParentId,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            SampleNumber: data?.SampleNumber,
            ReportNumber: data?.ReportNumber,
            PropId: data?.PropId,
            UserName: data?.UserName,
            DisplayName: data?.DisplayName,
        }))
        return window.open(`/export-contacts-overview`, "_blank", windowDimesions("1875px", "700px"));
    };

    if (windowType === WindowTypes.REPORT_CONTACTS) {
        localStorage.setItem("report-contacts", JSON.stringify({
            ContactId: data?.ContactId,
            ProductId: data?.ProductId,
            SiteSetId: data?.SiteSetId,
            ParentId: data?.ParentId,
            ReportNumber: data?.ReportNumber,

            SampleNumber: data?.SampleNumber,
            ComponentId: data?.ComponentId,
            UnitId: data?.UnitId,
            SiteId: data?.SiteId || data?.SiteID,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer || "",
        }))
        return window.open(`/report-contacts`, "_blank", windowDimesions("1875px", "850px"));
    };

    if (windowType === WindowTypes.SUBSCRIPTION_OVERVIEW) {
        localStorage.setItem("subscription-overview", JSON.stringify({
            ProductId: data?.ProductId,
            SiteSetId: data?.SiteSetId,
            ParentId: data?.ParentId,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
        }))
        return window.open(`/subscription-overview`, "_blank", windowDimesions("1875px", "820px"));
    };

    if (windowType === WindowTypes.UNIT_HOUR_HISTORY) {
        localStorage.setItem("unit-hour-history", JSON.stringify({ unitId: data?.UnitId }))
        return window.open(`/unit-hour-history`, "_blank", windowDimesions("1600px", "880px"));
    }
    if (windowType === WindowTypes.CRITIICAL_LOGS) {
        if (tabCheck === true) {
            return window.open(`/reports/critical-logs`, "_blank").focus();
        }
        else {
            return window.open(`/reports/critical-logs`, "_blank", windowDimesions("1900px", "900px"));
        }
    }

    if (windowType === WindowTypes.CRITIICAL_LOG) {
        localStorage.setItem("add-critical-logs", JSON.stringify({ SampleNumber: data?.SampleNumber }))
        return window.open(`/add-critical-logs?sn=${data?.SampleNumber || ""}`, "_blank", windowDimesions("1250px", "450px"));
    }

    if (windowType === WindowTypes.RERUN_SAMPLES) {
        if (tabCheck === true) {
            return window.open(`/reports/rerun-samples`, "_blank").focus();
        }
        else {
            return window.open(`/reports/rerun-samples`, "_blank", windowDimesions("1900px", "900px"));
        }
    }

    if (windowType === WindowTypes.MISSED_SAMPLES) {
        return window.open(`/missed-samples`, "_blank", windowDimesions("1900px", "900px"));
    }

    if (windowType === WindowTypes.HOLD_MANAGEMENT) {
        localStorage.setItem("hold-management", JSON.stringify({
            SampleNumber: data?.SampleNumber,
            unitId: data?.UnitId
        }))
        return window.open(`/management/hold-management`, "_blank", windowDimesions("1700px", "820px"));
    }

    if (windowType === WindowTypes.RECENT_UNIT_RESULTS) {
        localStorage.setItem("recent-unit-results", JSON.stringify({ UnitId: data?.UnitId }))
        return window.open(`/recent-unit-results`, "_blank", windowDimesions("1900px", "830px"));
    }

    if (windowType === WindowTypes.RECENT_COMPONENT_RESULTS) {
        localStorage.setItem("recent-component-results", JSON.stringify({ ComponentId: data?.ComponentId }))
        return window.open(`/recent-component-results`, "_blank", windowDimesions("1900px", "830px"));
    }

    if (windowType === WindowTypes.FLUID_WATCH) {
        if (tabCheck === true) {
            return window.open(`/master-data/FluidWatch?urlfluidId=${data?.FluidId || 1}&fluidTypeId=${data?.FluidTypeId || 1}`,
                "_blank").focus();
        }
        else {
            return window.open(`/master-data/FluidWatch?urlfluidId=${data?.FluidId || 1}&fluidTypeId=${data?.FluidTypeId || 1}`,
                "_blank", windowDimesions("900px", "350px"));
        }
    }

    if (windowType === WindowTypes.SEARCH_FLUID) {
        localStorage.setItem("search-fluid", JSON.stringify({ FluidTypeId: data?.FluidTypeId, FluidId: data?.FluidId }))
        return window.open(`/search-fluid`, "_blank", windowDimesions("1250", "450"));
    }

    if (windowType === WindowTypes.SEARCH_REGISTRATION) {
        if (tabCheck === true) {
            return window.open(`/registrations/search`, "_blank").focus();
        }
        else {
            return window.open(`/registrations/search`, "_blank", windowDimesions("1900px", "850px"));
        }
    }

    if (windowType === WindowTypes.CHART) {
        localStorage.removeItem("isCloseWindow");
        localStorage.setItem("isCloseWindow", false);
        localStorage.setItem("chartsData", JSON.stringify({ EqpMachineId: data?.EqpMachineId > 0 ? data?.EqpMachineId : 0, sampleId: data?.SampleNumber, hideProp: 1 }));
        return window.open(`/chart`, "_blank", windowDimesions("1900px", "1000"));
    }

    if (windowType === WindowTypes.HOLD) {
        const url = `/hold?urlsampleNumber=${data?.SampleNumber}`;
        return window.open(url, "_blank", windowDimesions("1600px", "750px"));
    }

    if (windowType === WindowTypes.IMAGES) {
        const url = `/images?urlsampleNumber=${data?.SampleNumber}`;
        return window.open(url, "_blank", windowDimesions("1400px", "750px"));
    }

    if (windowType === WindowTypes.EDIT_REGISTRATION) {
        localStorage.setItem("edit-registration", JSON.stringify({ regNo: data }));
        return window.open(`/trays/edit-registration`, "_blank", windowDimesions("1500px", "860px"));
    }

    if (windowType === WindowTypes.ADD_CONTACT) {
        // localStorage.setItem("add-contact", JSON.stringify({ ContactId: data?.ContactId }));
        return window.open(`/management/add-contact`, "_blank", windowDimesions("1800px", "800px"));
    }

    if (windowType === WindowTypes.ADD_NEW_CUSTOMER) {
        localStorage.setItem("edit-customer", JSON.stringify({ StartLetter: data?.StartLetter, ProductId: data?.ProductId, }));
        return window.open("/add-new-customer", "_blank", windowDimesions("1400px", "700px"));
    }
    if (windowType === WindowTypes.ADD_NEW_SITE) {
        localStorage.setItem("edit-site", JSON.stringify({ StartLetter: data?.StartLetter, ProductId: data?.ProductId, CustomerId: data?.CustomerId, Customer: data?.Customer, }));
        return window.open("/add-new-site", "_blank", windowDimesions("1400px", "810px"));
    }

    if (windowType === WindowTypes.ADD_NEW_UNIT) {
        localStorage.setItem("edit-unit", JSON.stringify({
            ProductId: data?.ProductId,
            Product: data?.Product,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            Site: data?.Site,
            StartLetter: data?.StartLetter
        }));
        return window.open("/add-new-unit", "_blank", windowDimesions("1400px", "500px"));
    };

    if (windowType === WindowTypes.ADD_NEW_COMPONENT) {
        localStorage.setItem("edit-component", JSON.stringify({
            ProductId: data?.ProductId,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            StartLetter: data?.StartLetter
        }));
        return window.open("/add-new-component", "_blank", windowDimesions("1400px", "500px"));
    }
    if (windowType === WindowTypes.ADD_EVENT) {
        localStorage.setItem("edit-event", JSON.stringify({
            ProductId: data?.ProductId,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            EventLogId: data?.EventLogId,
        }));
        return window.open("/add-event", "_blank", windowDimesions("1400px", "500px"));
    }

    if (windowType === WindowTypes.NEW_UNIT_MODEL) {
        // localStorage.setItem("new-unit-model", JSON.stringify({ ...data }));
        return window.open("/new-unit-model", "_blank", windowDimesions("800px", "300px"));
    }

    if (windowType === WindowTypes.EDIT_CUSTOMER) {
        localStorage.setItem("edit-customer", JSON.stringify({ StartLetter: data?.StartLetter, ProductId: data?.ProductId, CustomerId: data?.CustomerId }));
        return window.open("/edit-customer", "_blank", windowDimesions("1400px", "700px"));
    }
    if (windowType === WindowTypes.EDIT_SITE) {
        localStorage.setItem("edit-site", JSON.stringify({ StartLetter: data?.StartLetter, ProductId: data?.ProductId, CustomerId: data?.CustomerId, Customer: data?.Customer, SiteId: data?.SiteId }));
        return window.open("/edit-site", "_blank", windowDimesions("1400px", "810px"));
    }
    if (windowType === WindowTypes.EDIT_UNIT) {
        localStorage.setItem("edit-unit", JSON.stringify({
            ProductId: data?.ProductId,
            Product: data?.Product,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            Site: data?.Site,
            UnitId: data?.UnitId,
            StartLetter: data?.StartLetter
        }));
        return window.open("/edit-unit", "_blank", windowDimesions("1400px", "500px"));
    };
    if (windowType === WindowTypes.EDIT_COMPONENT) {
        localStorage.setItem("edit-component", JSON.stringify({
            ComponentId: data?.ComponentId,
            ProductId: data?.ProductId,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            StartLetter: data?.StartLetter
        }));
        return window.open("/edit-component", "_blank", windowDimesions("1400px", "500px"));
    }

    if (windowType === WindowTypes.EDIT_SAMPLE) {
        if (data?.SampleNumber > 0) {
            localStorage.setItem("edit-sample", JSON.stringify({ ...data, StartLetter: data?.StartLetter, sampleNumber: data?.SampleNumber, historySample: data?.historySample || "" }));
            return window.open(`/trays/edit-sample`, "_blank", windowDimesions("1800px", "750px"));
        }
        else return;
    }

    if (windowType === WindowTypes.EDIT_TRAY) {
        localStorage.setItem("edit-tray", JSON.stringify({ ...data }));
        return window.open(`/trays/edit-tray`, "_blank", windowDimesions("1800px", "800px"));
    }


    if (windowType === WindowTypes.EDIT_CONTACT) {
        localStorage.setItem("edit-contact", JSON.stringify({ ContactId: data?.ContactId }));
        return window.open(`/management/edit-contact`, "_blank", windowDimesions("1800px", "800px"));
    }

    if (windowType === WindowTypes.EDIT_EVENT) {
        localStorage.setItem("edit-event", JSON.stringify({
            ...data,
            ProductId: data?.ProductId,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            EventLogId: data?.EventLogId,
            StartLetter: data?.StartLetter,
        }));
        return window.open("/edit-event", "_blank", windowDimesions("1400px", "750px"));
    }

    if (windowType === WindowTypes.VIEW_EVENT) {
        localStorage.setItem("view-event", JSON.stringify({
            ProductId: data?.ProductId,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            EventLogId: data?.EventLogId,
            StartLetter: data?.StartLetter,
        }));
        return window.open("/view-event", "_blank", windowDimesions("1400px", "750px"));
    }

    if (windowType === WindowTypes.MANAGE_SAMPLE) {
        localStorage.setItem("manage-sample", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            SampleNumber: data?.SampleNumber,
        }));
        return window.open(`/manage-sample`, "_blank", windowDimesions("1500px", "800px"));
    }

    if (windowType === WindowTypes.MANAGE_COMPONENT) {
        localStorage.setItem("manage-component", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
        }));
        return window.open(`/manage-component`, "_blank", windowDimesions("1600px", "800px"));
    }

    if (windowType === WindowTypes.MANAGE_UNIT) {
        localStorage.setItem("manage-unit", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
        }));
        return window.open(`/manage-unit`, "_blank", windowDimesions("1500px", "800px"));
    }

    if (windowType === WindowTypes.MANAGE_SITE) {
        localStorage.setItem("manage-site", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
        }));
        return window.open(`/manage-site`, "_blank", windowDimesions("1500px", "750px"));
    }
    if (windowType === WindowTypes.MANAGE_CUSTOMER) {
        localStorage.setItem("manage-customer", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
        }));
        return window.open(`/manage-customer`, "_blank", windowDimesions("1500px", "750px"));
    }
    if (windowType === WindowTypes.RETURN_TO_LAB) {
        localStorage.setItem("return-to-lab", JSON.stringify({
            SampleNumber: data?.SampleNumber,
            Tray: data?.Tray,
            Laboratory: data?.Laboratory,
            ScreenName: data?.ScreenName,
            SampleDate: data?.Sampled,
            Option: data?.Option,
        }));
        return window.open(`/return-to-lab`, "_blank", windowDimesions("800px", "350px"));
    }

    if (windowType === WindowTypes.SPECIAL_UNIT_REPORT) {
        localStorage.setItem("special-report", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
        }));
        return window.open(`/special-unit-report`, "_blank", windowDimesions("800px", "450px"));
    }
    if (windowType === WindowTypes.SPECIAL_COMPONENT_REPORT) {
        localStorage.setItem("special-report", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
        }));
        return window.open(`/special-component-report`, "_blank", windowDimesions("800px", "450px"));
    }
    if (windowType === WindowTypes.REPORTS_BY_DATE) {
        return window.open(`/reports-by-date`, "_blank", windowDimesions("700px", "300px"));
    }
    if (windowType === WindowTypes.UNITS_NO_RECENT_SAMPLES) {
        localStorage.setItem("units-no-recent-samples", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
        }));
        return window.open(`/units-no-recent-samples`, "_blank", windowDimesions("1500px", "700px"));
    }
    if (windowType === WindowTypes.COMPONENTS_NO_RECENT_SAMPLES) {
        localStorage.setItem("components-no-recent-samples", JSON.stringify({
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
        }));
        return window.open(`/components-no-recent-samples`, "_blank", windowDimesions("1500px", "700px"));
    }
    if (windowType === WindowTypes.SEARCH_CONTACT) {
        localStorage.setItem("search-contact", JSON.stringify({
            selectCheck: data?.selectCheck,
            ProductId: data?.ProductId,
            StartLetter: data?.StartLetter,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
        }));
        return window.open(`/management/search-contact`, "_blank", windowDimesions("1700px", "800px"));
    }

    if (windowType === WindowTypes.KOMATSU_CONTACTS) {
        return window.open(`/admin/komatsu-contacts`, "_blank", windowDimesions("700", "350"));
    }

    if (windowType === WindowTypes.MATCH_MACHINES_INSITE) {
        return window.open(`/admin/match-machines-insite`, "_blank", windowDimesions("700", "350"));
    }

    if (windowType === WindowTypes.APLLY_CMS_ROLE) {
        return window.open(`/admin/apply-cms-role`, "_blank", windowDimesions("700", "350"));
    }

}

export const WindowTypes = {
    CRITIICAL_LOG: "critical-log",
    CRITIICAL_LOGS: "critical-logs",
    RERUN_SAMPLES: "rerun-samples",
    MISSED_SAMPLES: "missed-samples",
    WEBSITE_CONTACTS: "website-contacts",
    ACCESS_PERMISSION_OVERVIEW: "access-permission-overview",
    PRIMARY_CONTACTS_OVERVIEW: "primary-contacts-overview",
    CONTACT_ACCESS_PERMISSION: "contact-access-permission",
    CONTACT_PRIMARY_MAPPING: "contact-primary-mapping",

    EXPORT_CONTACTS_OVERVIEW: "export-contacts-overview",
    EXPORT_CONTACTS_ID_OVERVIEW: "export-contacts-id-overview",
    REPORT_CONTACTS: "report-contacts",
    SUBSCRIPTION_OVERVIEW: "subscription-overview",
    UNIT_HOUR_HISTORY: "unit-hour-history",
    HOLD_MANAGEMENT: "hold-management",
    RECENT_UNIT_RESULTS: "recent-unit-results",
    RECENT_COMPONENT_RESULTS: "recent-component-results",
    FLUID_WATCH: "fluid-watch",
    SEARCH_FLUID: "search-fluid",
    REPORTS_BY_DATE: "reports-by-date",
    SEARCH_CONTACT: "search-contact",
    SEARCH_GENERAL: "search-general",

    SEARCH_REGISTRATION: "search-registration",
    CHART: "chart",
    HOLD: "hold",
    IMAGES: "images",
    SPECIAL_COMPONENT_REPORT: "special-component-report",
    SPECIAL_UNIT_REPORT: "special-unit-report",
    COMPONENTS_NO_RECENT_SAMPLES: "components-no-recent-samples",
    UNITS_NO_RECENT_SAMPLES: "units-no-recent-samples",
    VIEW_EVENT: "view-event",

    ADD_NEW_CUSTOMER: "add-new-customer",
    ADD_NEW_COMPONENT: "add-new-component",
    ADD_NEW_UNIT: "add-new-unit",
    ADD_NEW_SITE: "add-new-site",
    ADD_SAMPLE_IMAGES: "add-sample-images",
    ADD_EVENT: "add-event",
    ADD_CONTACT: "add-contact",
    NEW_UNIT_MODEL: "new-unit-model",

    EDIT_CONTACT: "edit-contact",
    EDIT_REGISTRATION: "edit-registration",
    EDIT_COMPONENT: "edit-component",
    EDIT_UNIT: "edit-unit",
    EDIT_SITE: "edit-site",
    EDIT_CUSTOMER: "edit-customer",
    EDIT_TRAY: "edit-tray",
    EDIT_SAMPLE: "edit-sample",
    EDIT_EVENT: "edit-event",

    MANAGE_SAMPLE: "manage-sample",
    MANAGE_COMPONENT: "manage-component",
    MANAGE_UNIT: "manage-unit",
    MANAGE_SITE: "manage-site",
    MANAGE_CUSTOMER: "manage-customer",
    RETURN_TO_LAB: "return-to-lab",

    KOMATSU_CONTACTS: "komatsu-contacts",
    MATCH_MACHINES_INSITE: "match-machines-insite",
    APLLY_CMS_ROLE: "apply-cms-role",
}

export const WindowRoutes = [

    "/admin/komatsu-contacts",
    "/admin/match-machines-insite",
    "/admin/apply-cms-role",

    "/access-permission-overview",
    "/primary-contacts-overview",
    "/contact-primary-mapping",
    "/export-contacts-overview",
    "/report-contacts",
    "/subscription-overview",
    "/unit-hour-history",
    "/add-critical-logs",
    "/management/hold-management",
    "/recent-unit-results",
    "/recent-component-results",
    "/master-data/FluidWatch",
    "/trays/edit-registration",
    "/registrations/search",
    "/chart",
    "/Chart",
    "/hold",
    "/images",
    "/missed-samples",
    "/special-unit-report",
    "/special-component-report",

    "/search-general",
    "/view-event",

    "/add-new-customer",
    "/add-new-component",
    "/add-new-unit",
    "/add-new-site",
    "/add-sample-images",
    "/add-event",

    "/edit-registration",
    "/edit-component",
    "/edit-unit",
    "/edit-site",
    "/edit-customer",
    "/edit-event",
    "/trays/edit-tray",
    "/trays/edit-sample",

    "/manage-sample",
    "/manage-component",
    "/manage-unit",
    "/manage-site",
    "/manage-customer",
    "/management/add-contact",
    "/management/edit-contact",

    "/reports/critical-logs",
    "/reports/rerun-samples",

    "/return-to-lab",
    "/search-fluid",
    "/reports-by-date",
    "/components-no-recent-samples",
    "/units-no-recent-samples",

    "/search-contact",
    "/contact-access-permission",

    "/new-unit-model",
];